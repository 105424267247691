
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat.define-palette(mat.$indigo-palette);
$app-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-theme: mat.define-light-theme((
  color: (
    primary: $app-primary,
    accent: $app-accent,
    warn: $app-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  /** primary **/
  --ion-color-primary: #009CDB;
  --ion-color-primary-rgb: 0,156,219;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #017eaf;
  --ion-color-primary-tint: #227ca0;

  /** secondary **/
  --ion-color-secondary: #a5a5a5;
  --ion-color-secondary-rgb: 165, 165, 165;
  --ion-color-secondary-contrast: #1f1f1f;
  --ion-color-secondary-contrast-rgb: 31, 31, 31;
  --ion-color-secondary-shade: #1f1f1f;
  --ion-color-secondary-tint: #d4d4d4;

  /** tertiary **/
  --ion-color-tertiary: #464646;
  --ion-color-tertiary-rgb: 34, 34, 34;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #464646;
  --ion-color-tertiary-tint: #464646;

  /** success **/
  --ion-color-success: #57a35f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /** celle **/
  --ion-color-celle: #ffffff;
  --ion-color-celle-rgb: 255, 255, 255;
  --ion-color-celle-contrast: #1f1f1f;
  --ion-color-celle-contrast-rgb: 31, 31, 31;
  --ion-color-celle-shade: #1f1f1f;
  --ion-color-celle-tint: #d4d4d4;



  --ion-background-color: #eeeeee;
  --ion-background-color-rgb: 237, 237, 237;

  --ion-text-color: #1f1f1f;
  --ion-text-color-rgb: 31, 31, 31;

  --ion-toolbar-background: #eeeeee;

  --ion-tab-bar-background: #ffffff;

  --ion-card-background: #ffffff;

}





@media (prefers-color-scheme: dark) {
  /*
   Dark Colors
   -------------------------------------------
   */

  body {
    /** primary **/
    --ion-color-primary: #009CDB;
    --ion-color-primary-rgb: 0, 156, 219;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #017eaf;
    --ion-color-primary-tint: #227ca0;

    /** secondary **/
    --ion-color-secondary: #a5a5a5;
    --ion-color-secondary-rgb: 165, 165, 165;
    --ion-color-secondary-contrast: #1f1f1f;
    --ion-color-secondary-contrast-rgb: 31, 31, 31;
    --ion-color-secondary-shade: #1f1f1f;
    --ion-color-secondary-tint: #d4d4d4;

    /** tertiary **/
    --ion-color-tertiary: #464646;
    --ion-color-tertiary-rgb: 34, 34, 34;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #464646;
    --ion-color-tertiary-tint: #464646;

    /** success **/
    --ion-color-success: #0ca41c;
    --ion-color-success-rgb: 12, 164, 28;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    /** warning **/
    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    /** danger **/
    --ion-color-danger: #d6001c;
    --ion-color-danger-rgb: 214, 0, 28;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    /** dark **/
    --ion-color-dark: #222428;
    --ion-color-dark-rgb: 34, 36, 40;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1e2023;
    --ion-color-dark-tint: #383a3e;

    /** medium **/
    --ion-color-medium: #92949c;
    --ion-color-medium-rgb: 146, 148, 156;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #808289;
    --ion-color-medium-tint: #9d9fa6;

    /** light **/
    --ion-color-light: #f4f5f8;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;

    /** celle **/
    --ion-color-celle: #ffffff;
    --ion-color-celle-rgb: 255, 255, 255;
    --ion-color-celle-contrast: #1f1f1f;
    --ion-color-celle-contrast-rgb: 31, 31, 31;
    --ion-color-celle-shade: #1f1f1f;
    --ion-color-celle-tint: #d4d4d4;



    --ion-background-color: #eeeeee;
    --ion-background-color-rgb: 237, 237, 237;

    --ion-text-color: #1f1f1f;
    --ion-text-color-rgb: 31, 31, 31;

    --ion-toolbar-background: #fafafa;

    --ion-tab-bar-background: #ffffff;

    --ion-card-background: #ffffff;
  }

  /*
   * iOS Dark Theme
   * -------------------------------------------
   */

  .ios body {
    --ion-background-color: #eeeeee;
    --ion-background-color-rgb: 237, 237, 237;

    --ion-text-color: #1f1f1f;
    --ion-text-color-rgb: 31, 31, 31;

    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;
  }



  .ios ion-modal {
    --ion-background-color: var(--ion-color-step-100);
    --ion-toolbar-background: #ffffff;
    --ion-toolbar-border-color: var(--ion-color-step-250);
    --ion-text-color: #1f1f1f;
    --ion-text-color-rgb: 31, 31, 31;
  }

  /*
   * Material Design Dark Theme
   * -------------------------------------------
   */

  .md body {
    --ion-background-color: #eeeeee;
    --ion-background-color-rgb: 237, 237, 237;

    --ion-text-color: #1f1f1f;
    --ion-text-color-rgb: 31, 31, 31;

    --ion-border-color: #f3f3f3;
    --ion-color-step-50: #0d0d0d;
    --ion-color-step-100: #1a1a1a;
    --ion-color-step-150: #262626;
    --ion-color-step-200: #333333;
    --ion-color-step-250: #404040;
    --ion-color-step-300: #4d4d4d;
    --ion-color-step-350: #595959;
    --ion-color-step-400: #666666;
    --ion-color-step-450: #737373;
    --ion-color-step-500: #808080;
    --ion-color-step-550: #8c8c8c;
    --ion-color-step-600: #999999;
    --ion-color-step-650: #a6a6a6;
    --ion-color-step-700: #b3b3b3;
    --ion-color-step-750: #bfbfbf;
    --ion-color-step-800: #cccccc;
    --ion-color-step-850: #d9d9d9;
    --ion-color-step-900: #e6e6e6;
    --ion-color-step-950: #f2f2f2;




    --ion-item-background: #ffffff;

    --ion-toolbar-background: #ffffff;

    --ion-tab-bar-background: #ffffff;

    --ion-card-background: #ffffff;

  }

  .md  ion-modal {
    --ion-background-color: var(#ffffff);
    --ion-toolbar-background: #ffffff;
    --ion-toolbar-border-color: var(--ion-color-step-250);
    --ion-text-color: #1f1f1f;
    --ion-text-color-rgb: 31, 31, 31;
  }


}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
