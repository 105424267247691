/*
 ? App Global CSS
 ? ----------------------------------------------------------------------------
 ? Put style rules here that you want to apply globally. These styles are for
 ? the entire app and not just one component. Additionally, this file can be
 ? used as an entry point to import other CSS/Sass files to be included in the
 ? output CSS.
 ? For more information on global stylesheets, visit the documentation:
 ? https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~@angular/material/prebuilt-themes/indigo-pink.css';


html,
body {
  height: 100% !important;
  width: 100% !important;
}

body {
  margin: 0;
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-text-size-adjust: none;
}

* {
  font-family: "Heebo", sans-serif;
  font-optical-sizing: auto;
}


ion-title {
  color: #009CDB;
}

ion-modal {
  --border-radius: 5px;
}

ion-header {
  padding-left: 1vh;
}

#backIcon {
  font-size: 3.5vh;
}


ion-accordion-group {
  background-color: white;
}

ion-accordion {
  background-color: white;
}

ion-searchbar {
  --background: #ffffff;
}

ion-textarea {
  color: #1f1f1f;
}

ion-modal {
  color: #1f1f1f;
}

ion-alert {
  .alert-wrapper {
    --background: white;
  }
}

select {
  background-color: white;
}

ion-toolbar {
  margin: 0;
  padding: 0;
}

ion-header {
  --background: white;
  margin: 0;
  padding: 0;
}

.modal {
  .modal-wrapper {
    --width: 95%;

    display: block;
  }
}


.small {
  --height: 50%;

  &::part(content) {
    padding: 2.5vh;
    border-radius: 33px;
  }
}

.long {
  --height: 80%;
}

.big {
  --width: 60%;
}

ion-popover {
  &::part(content) {
    --width: 30vh;
  }
}

.mat-tab-body-wrapper {
  flex-grow: 1;
}

.bluePopover {
  --background: #009CDB;
  --color: white;
}

.bluePopover ion-content {
  --background: #009CDB;
  --color: white;
}

ion-item:disabled {
  color: --ion-color-light;
  opacity: 1;
}

.header-row {
  background-color: #009CDB;
  border-radius: 6px 6px 0 0;
  color: white;
  padding: 0.5vh;
  font-weight: 600;
}

.colored:nth-child(odd) {
  background-color: #f6f5f5;
  padding: 1vh;
}

.colored:nth-child(even) {
  background-color: white;
  padding: 1vh;
}

.colored:first-child {
  border-radius: 6px 6px 0 0;
  margin-top: 2vh;
}

.colored:last-child {
  border-radius: 0 0 6px 6px;
}

.colored:hover {
  cursor: pointer;
  color: #009CDB;

  .row-text {
    color: #009CDB;
  }

}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}


.modalCtrlFullWidth {
  --width: 100%;
  --height: 95%;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;

  &::part(content) {
    margin-top: 5vh;
  }
}


ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}
